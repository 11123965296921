import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// import { Typography } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { differenceInSeconds } from 'date-fns';
import { getStat } from 'store/actions/statisticsActions';
// import { fetchCompanyProviders, fetchWorkingTime } from 'store/actions';
import { Page } from 'components';
import StatisticsLoading from './components/StatisticsLoading';
// import TagTable from './components/TagTable';
// import SearchBar from './components/SearchBar';
import Filters from './components/Filters';
// import StatFilters from './components/StatFilters';
import StatNav from './components/StatNav';
import LineChart from './components/LineChart';
import { getDateRange } from './utils';


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
}));

export default function WorkTimePage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.statistics.selectedDepartment);
  const provider = useSelector((state) => state.statistics.selectedProviders);
  const statisticsData = useSelector((state) => state.statistics.statistics);
  const company = useSelector((state) => state.stat.company);
  const uuidCompany = useSelector((state) => state.session.selectedCompany);
  const selectedProviders = useSelector((state) => state.statistics.selectedProviders);
  const selectedDepartment = useSelector((state) => state.statistics.selectedDepartment);
  const statisticsIsSubscribed = useSelector(
    (state) => state.statistics.isSubscribed,
  );
  const workTime = useSelector((state) => state.workTime);
  const calendarDateRange = useSelector((state) => state.calendar.mainDate);
    const [selectedCompany, setSelectedCompany] = useState(
    company || uuidCompany,
  );
  const checkByData = () => {
    const { stat } = workTime;
    const resultArray = stat?.reduce((acc, currentItem) => {
      // Ищем элемент в аккумуляторе с таким же createdBy
      const existingItem = acc.find((item) => item.createdBy === currentItem.createdBy);
      console.log(existingItem);
      if (existingItem) {
        // Если элемент уже существует, объединяем массивы hours
        existingItem.hours = existingItem.hours.concat(currentItem.hours);
      } else {
        // Если элемент не найден, добавляем текущий элемент в аккумулятор
        acc.push(currentItem);
      }
      console.log(acc);
      return acc;
    }, []);
    console.log(resultArray);
  }
  useEffect(() => {
    checkByData();
  }, [workTime]);
  const newQuestionsData = {
    total: statisticsData?.others?.total?.new
      ? statisticsData.others.total.new
      : 0,
    lineChartCallbacks: {},
    labels: statisticsData?.others?.x
      ? statisticsData.others.x.map((l) => l.label)
      : [],
    datasets: [
      {
        label: t('graphInfo.graphTitle1'),
        data: statisticsData?.others?.x
          ? statisticsData.others.x.map((i) => i.data.new)
          : [],
        backgroundColor: '#006AE4',
        borderColor: '#006AE4',
        borderWidth: 1,
      },
    ],
  };
  console.log(newQuestionsData);
  const resolvedIssuesData = {
    total: statisticsData?.others?.total?.closed
      ? statisticsData.others.total.closed
      : 0,
    lineChartCallbacks: {},
    labels: statisticsData?.others?.x
      ? statisticsData.others.x.map((i) => i.label)
      : [],
    datasets: [
      {
        label: t('graphInfo.graphTitle2'),
        data: statisticsData?.others?.x
          ? statisticsData.others.x.map((i) => i.data.closed)
          : [],
        backgroundColor: '#00CA2C',
        borderColor: '#00CA2C',
        borderWidth: 1,
      },
    ],
  };
  const getData = async () => {
    const modifiedDateRange = getDateRange(calendarDateRange);
    // dispatch(
    //   fetchWorkingTime({
    //     uuidUser: user?.assignee,
    //     from: modifiedDateRange.from,
    //     to: modifiedDateRange.to,
    //     provider,
    //     departmentId: company,
    //   }),
    // );
  };
  useEffect(() => {
    if (company) {
      setSelectedCompany(company);
      // dispatch(fetchCompanyProviders({ uuidCompany: company }));
    }
  }, [company]);

  // useEffect(() => {
  //   filteredByDate();
  // }, [JSON.stringify(dialogs)]);
  function transformDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }
  const calculateTimeDifference = (start, end) => {
    const startTime = new Date(start);
    let endTime;
    if (end) {
      endTime = new Date(end);
    } else {
      endTime = new Date();
    }
    return differenceInSeconds(endTime, startTime);
  };

  const getWorkingSeconds = () => {
    const totalSeconds = workTime?.stat?.reduce((acc, workItem) => {
      const times = workItem?.hours.reduce((accum, hour) => {
        const diffInSec = calculateTimeDifference(
          hour.work?.start,
          hour.work?.end,
        );
        return accum + diffInSec;
      }, 0)
      return acc + times;
    }, 0);
    return totalSeconds;
  };
  console.log(getWorkingSeconds());
  useEffect(() => {
    getData();
  }, [calendarDateRange, provider, company, user]);
  useEffect(() => {
    if (calendarDateRange && statisticsIsSubscribed) {
      const modifiedDateRange = {
        from: Array.isArray(calendarDateRange.dateBefore)
          ? transformDate(calendarDateRange.dateBefore[0])
          : transformDate(calendarDateRange.dateBefore),
        to: transformDate(calendarDateRange.dateAfter),
      };
      getStat({
        uuidCompany: selectedCompany,
        dateRange: modifiedDateRange,
        uuidConnection: selectedProviders,
        uuidAssigns: selectedDepartment.assignee,
      })(dispatch);
    }
  }, [calendarDateRange, selectedCompany, statisticsIsSubscribed, selectedProviders, selectedDepartment]);

  return (
    <Page
      className={clsx({ [classes.root]: true })}
      style={{ flexDirection: 'column' }}
      title="IntellectDialog | Stat"
    >
      <StatNav />
      <Wrap>
        {/* <Filters selectedCompany={selectedCompany} dateRange={dateRange} /> */}
        <StatisticsLoading/>
        <Filters selectedCompany={selectedCompany} />
        <StyledStat>
          <GraphWrap>
            <LineChart
              title='Отработанные часы'
              statisticQuantity1={newQuestionsData.total}
              statisticDesc1='Всего отработанных часов'
              // statisticQuantity2="0"
              // statisticDesc2={t('generalPage.LineChart1.desc2')}
              desc={t('Отработанные часы по дням', {
                time: `${
                  statisticsData?.others?.key
                    ? t(`time.${statisticsData.others.key}`)
                    : ''
                }`,
              })}
              // question={t('graphInfo.graphQuestion1')}
              // question2={t('graphInfo.graphQuestion1dot2')}
              footer
              data={newQuestionsData}
              propCallbacks={newQuestionsData.lineChartCallbacks}
            />
          </GraphWrap>
          <GraphWrap>
            <LineChart
              title={t('generalPage.LineChart2.title')}
              statisticQuantity1={resolvedIssuesData.total}
              statisticDesc1={t('generalPage.LineChart2.desc1')}
              // statisticQuantity2="30"
              // statisticDesc2={t('generalPage.LineChart2.desc2')}
              desc={t('generalPage.LineChart2.desc', {
                time: `${
                  statisticsData?.others?.key
                    ? t(`time.${statisticsData.others.key}`)
                    : ''
                }`,
              })}
              question={t('graphInfo.graphQuestion2')}
              question2={t('graphInfo.graphQuestion2dot2')}
              footer
              data={resolvedIssuesData}
              propCallbacks={resolvedIssuesData.lineChartCallbacks}
            />
          </GraphWrap>
        </StyledStat>
      </Wrap>
    </Page>
  );
}

const StyledStat = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1055px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  grid-column-gap: 50px;
  grid-row-gap: 32px;
  margin-bottom: 100px;
  padding-bottom: 60px;
`;

const GraphWrap = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1515px) {
    .lineChart-wrapper {
      min-height: 189px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  h5.graph-title {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 25px;
    color: #202020;
  }
  .statistic-quantity {
    font-weight: 400;
    font-size: 28px;
    line-height: 39px;
    color: #454545;
  }

  .statistic-desc {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #7f7f7f;
  }

  .statistic-question-mark {
    min-width: 12px;
    height: 12px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    margin-left: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .statistic-question-mark:before {
    content: '\\003F';
    font-size: 8px;
  }
  .statistic-question {
    top: 140%;
    color: #fff;
    width: 200px;
    text-align: center;
    padding: 7px 15px;
    position: absolute;
    display: none;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.80);
  }
  .statistic-question2 {
    left: 7%;
  }
  .statistic-question:after {
    content: '';
    position: absolute;
    bottom: calc(100%);
    left: calc(50% - 5.5px);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 9px solid rgba(0,0,0,0.5);
}
  }
  .statistic-question-mark:hover .statistic-question {
    display: block;
  }

  .graph-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #454545;
    margin-bottom: 12px;
  }

  .lineChart-wrapper {
  }
`;

export const Wrap = styled.div`
  width: 100%;
  padding: 32px 40px 0 40px;
  background-color: #fff;
  .title {
    padding-bottom: 24px;
    font-weight: 400;
    font-size: 34px;
    line-height: 47px;
  }
  .section {
    padding-bottom: 32px;
  }
`;
