import { Box, Typography } from '@mui/material';
import AppSwitcher from 'components/AppSwitcher/AppSwitcher';
import { styled } from '@mui/material/styles';
import { ReactComponent as MessageSquareCode } from '../../../../assets/img/user.svg';

import { useTranslation } from 'react-i18next';
import {
  getCompanySetting,
  updateShowEmployeeInTg,
  setShowEmployeeInTg,
} from '../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';

export const SettingsCardStyled = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  padding: '24px',
  background: '#ffff',
  minHeight: '230px',
  boxSizing: 'border-box',
  '.header-container': {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
    minHeight: '32px',
  },
  '.card-title': {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    marginBottom: '4px',
  },
  '.card-text': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#8B8C8D',
    marginBottom: '20px',
  },
}));

const SettingsDialogs = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // @ts-expect-error
  const { selectedCompany } = useSelector((state) => state.session);

  const {
    showEmployeeInTg,
  // @ts-expect-error
  } = useSelector((state) => state.settings);

  useEffect(() => {
  // @ts-expect-error
    dispatch(getCompanySetting(selectedCompany, 'showEmployeeInTg')).then((value) => {
      dispatch(setShowEmployeeInTg(value === 'true'));
    });
  }, [dispatch, selectedCompany]);

  const handleTgInfoToggle = useCallback(() => {
    dispatch(updateShowEmployeeInTg(selectedCompany, !showEmployeeInTg));
  }, [dispatch, selectedCompany, showEmployeeInTg]);

  return (
    <Box
      sx={{
        maxWidth: 800,
        mx: 'auto',
        mt: 10,
        display: 'grid',
        height: 'fit-content',
        gap: 3,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        },
        flex: 1,
      }}
    >
      <SettingsCardStyled>
        <Box className="header-container">
          <MessageSquareCode />

          <AppSwitcher
            onChange={handleTgInfoToggle}
            isOn={showEmployeeInTg}
            size=""
          />
        </Box>
        <Typography className="card-title">
          {t('Отображение информации о сотруднике в Telegram')}
        </Typography>
        <Typography className="card-text">
          {"При включении данной функции, в Telegram'е над каждым сообщением сотрудника будет отображаться его ФИО и отдел"}
        </Typography>
      </SettingsCardStyled>
    </Box>
  );
};

export default SettingsDialogs;
