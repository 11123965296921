export { default } from './SettingsPage';

export const ROLES = {
  USER: 'user', // хозяин платформы
  EMPLOYEE: 'employee', // сотрудники
  DEVELOPER: 'developer', // руты/разработчики
  CLIENT: 'client', // владелец аккаунта
};
export const SETTINGS_ROUTES = {
  SCHEDULE: '/settings/schedule',
  EMPLOYEE_SCHEDULES: '/settings/employeeSchedules',
  ROUTING: '/settings/routing',
  PASSWORD: '/settings/password',
  NOTIFICATIONS: '/settings/notifications',
  DIALOGS: '/settings/dialogs',
  ACCESS_SETTINGS: '/settings/accessSettings',
};
export const SETTINGS_NAV_LINKS = [
  {
    title: 'custom', // Пользовательские
    availableFor: [ROLES.USER, ROLES.EMPLOYEE, ROLES.DEVELOPER, ROLES.CLIENT],
    links: [
      {
        name: 'security', // Безопасность
        sectionBy: 'custom', // Пользовательские
        iconName: 'security',
        path: SETTINGS_ROUTES.PASSWORD,
        isDisabled: false,
      },
      {
        name: 'notifications', // Уведомления
        sectionBy: 'custom', // Пользовательские
        iconName: 'notifications',
        path: SETTINGS_ROUTES.NOTIFICATIONS,
        isDisabled: false,
      },
    ],
  },
  {
    title: 'employees', // Сотрудники
    availableFor: [ROLES.USER, ROLES.EMPLOYEE, ROLES.DEVELOPER, ROLES.CLIENT],
    links: [
      {
        name: 'mySchedule', // Графики работ
        sectionBy: 'employees', // Сотрудники
        iconName: 'schedule',
        path: SETTINGS_ROUTES.SCHEDULE,
      },
      {
        name: 'employeesSchedule', // Графики работы сотрудниклв
        sectionBy: 'employees', // Сотрудники
        iconName: 'schedule',
        path: SETTINGS_ROUTES.EMPLOYEE_SCHEDULES,
      },
    ],
  },
  {
    title: 'chatCenter', // Чат-центр
    availableFor: [ROLES.USER, ROLES.DEVELOPER, ROLES.CLIENT],
    links: [
      {
        name: 'dialogs',
        iconName: 'dialogs',
        sectionBy: 'chatCenter',
        path: SETTINGS_ROUTES.DIALOGS,
      },
      // {
      //   name: 'accessSettings', // Настройки доступа
      //   iconName: 'accessSettings',
      //   sectionBy: 'chatCenter',
      //   path: SETTINGS_ROUTES.ACCESS_SETTINGS,
      // },
      {
        name: 'routing', // Маршрутизация
        iconName: 'routing',
        sectionBy: 'chatCenter',
        path: SETTINGS_ROUTES.ROUTING,
      },
    ],
  },
];

export const PERMISSIONS = {
  ROUTING: {
    READ: 'ROUTING_READ',
    WRITE: 'ROUTING_WRITE',
  },
};
