import { HOST } from 'config';
import { apiGet, apiPost } from '../../api';

export const SET_WORKING_TIME = 'SET_WORKING_TIME';
export const RESET_WORKING_TIME = 'RESET_WORKING_TIME';

export const updateWorkingTime = (payload) => ({
  type: SET_WORKING_TIME,
  payload,
});

export const resetWorkingTime = () => ({
  type: RESET_WORKING_TIME,
});

export const fetchWorkTimeCurrentState =
({ uuidUser, sessionId }) =>
  async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      apiPost({
        dispatch,
        url: `${HOST}/user/workTime/state`,
        tokens: getState().session.tokenData,
        body: {
          uuidUser,
          sessionId,
        },
        callback: (res) => {
          dispatch(updateWorkingTime(res));
          resolve(res);
        },
        errorCallback: reject,
      });
    });
  };

export const sendWorkingTime =
({ sessionId, uuidUser, uuidDepartment, type, date }) =>
  async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      apiPost({
        dispatch,
        url: `${HOST}/user/workTime`,
        tokens: getState().session.tokenData,
        body: {
          sessionId: sessionId || getState().workTime.sessionId,
          uuidUser,
          uuidDepartment,
          type,
          date,
        },
        callback: () => {
          dispatch(fetchWorkTimeCurrentState({ uuidUser, sessionId }))
            .then((res) => resolve(res))
            .catch(reject);
        },
        errorCallback: reject,
      });
    });
  };

export const fetchWorkingTime =
({ uuidUser }) =>
  async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      apiGet({
        dispatch,
        url: `${HOST}/user/workTime/latest?uuidUser=${uuidUser}`,
        tokens: getState().session.tokenData,
        callback: (res) => {
          if (!!res) {
            dispatch(updateWorkingTime(res));
          } else {
            dispatch(resetWorkingTime(res));
          }
          resolve(res);
        },
        errorCallback: (error) => {
          reject(error);
        },
      });
    });
  };